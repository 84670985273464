var exports = {};

exports = function (inp, opts) {
  if (Object.prototype.toString.call(inp) === "[object Object]") {
    opts = inp;
    inp = undefined;
  }

  if (inp !== undefined && Object.prototype.toString.call(inp) !== "[object Date]" && typeof inp !== "number") {
    return new TypeError("Expected a date object or a number");
  }

  if (typeof inp === "number" && (inp < 0 || inp > 6)) {
    return new RangeError("Expected the value of inp between 0-6");
  }

  opts = opts || {};
  var data = {};
  data.ne = {
    full: ["\u0906\u0907\u0924\u0935\u093E\u0930", "\u0938\u094B\u092E\u0935\u093E\u0930", "\u092E\u0917\u0932\u0935\u093E\u0930", "\u092C\u0941\u0927\u0935\u093E\u0930", "\u092C\u093F\u0939\u093F\u0935\u093E\u0930", "\u0936\u0941\u0915\u094D\u0930\u0935\u093E\u0930", "\u0936\u0928\u093F\u0935\u093E\u0930"],
    short: ["\u0906\u0907\u0924", "\u0938\u094B\u092E", "\u092E\u0917\u0932", "\u092C\u0941\u0927", "\u092C\u093F\u0939\u093F", "\u0936\u0941\u0915\u094D\u0930", "\u0936\u0928\u093F"],
    min: ["\u0906", "\u0938\u094B", "\u092E", "\u092C\u0941", "\u092C\u093F", "\u0936\u0941", "\u0936"]
  };
  data.en = {
    full: ["Aaitabaar", "Sombaar", "Mangalbaar", "Budhabaar", "Bihibaar", "Shukrabaar", "Shanibaar"],
    short: ["Aaita", "Som", "Mangal", "Budha", "Bihi", "Shukra", "Shani"],
    min: ["Aai", "So", "Man", "Bu", "Bi", "Shu", "Sha"]
  };
  var lang = "ne";

  if (opts.lang === "en") {
    lang = "en";
  }

  if (inp === undefined) {
    inp = new Date().getDay();
  }

  if (Object.prototype.toString.call(inp) === "[object Date]") {
    inp = inp.getDay();
  }

  if (!opts.type) {
    var nepday = {
      full: data[lang].full[inp],
      short: data[lang].short[inp],
      min: data[lang].min[inp]
    };
    return nepday;
  }

  switch (opts.type) {
    case "short":
      return data[lang].short[inp];

    case "min":
      return data[lang].min[inp];

    default:
      return data[lang].full[inp];
  } // should never be here :D


  return null;
};

export default exports;